<template>
    <div>
        <ValidationObserver ref="form">
        <div class="card card-custom mb-5">
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">{{ $t("settings.heading.emails") }}</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("settings.subheading.emails") }}</span>
                </div>
                <div class="card-toolbar">
                    <b-button class="btn btn-success mr-2" @click.prevent="saveSystemEmailSettings" ref="save_submit">{{ $t("button.save") }}</b-button>
                </div>
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email.sendername">{{ $t("settings.title.email_sendername") }}</label>
                    <div class="col-lg-9 col-xl-6">            
                        <ValidationProvider>
                        <input 
                            name="email.sendername"
                            id="email.sendername"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('settings.placeholder.email_sendername')"
                            v-model="settings.mail_from_name"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email.sendernoreply">{{ $t("settings.title.email_sendernoreply") }}</label>
                    <div class="col-lg-9 col-xl-6">            
                        <ValidationProvider>
                        <input 
                            name="email.sendernoreply"
                            id="email.sendernoreply"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('settings.placeholder.email_sendernoreply')"
                            v-model="settings.mail_from_address"
                            >    
                        
                        </ValidationProvider>    
                    </div>
                </div>
            </div>
        </div>
            <div class="card card-custom">
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">Nastavení SMTP</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("settings.title.smtp_subtitle") }}</span>
                </div>
            </div>
            <div class="card-body">  
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email.smtp.hostname">{{ $t("settings.title.hostname") }}</label>
                        <div class="col-lg-9 col-xl-6">            
                            <ValidationProvider>
                            <input 
                                name="email.smtp.hostname"
                                id="email.smtp.hostname"
                                type="text" 
                                class="form-control form-control-lg form-control-solid"
                                :placeholder="$t('settings.placeholder.hostname')"
                                v-model="settings.mail_smtp_host"
                                >    
                            </ValidationProvider>    
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email.smtp.port">{{ $t("settings.title.host_port") }}</label>
                        <div class="col-lg-9 col-xl-6">            
                            <ValidationProvider>
                            <input 
                                name="email.smtp.port"
                                id="email.smtp.port"
                                type="text" 
                                class="form-control form-control-lg form-control-solid"
                                :placeholder="$t('settings.placeholder.host_port')"
                                v-model="settings.mail_smtp_port"
                                >    
                            </ValidationProvider>    
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email.smtp.username">{{ $t("label.email") }}</label>
                        <div class="col-lg-9 col-xl-6">            
                            <ValidationProvider>
                            <input 
                                name="email.smtp.username"
                                id="email.smtp.username"
                                type="text" 
                                class="form-control form-control-lg form-control-solid"
                                :placeholder="$t('placeholder.username')"
                                v-model="settings.mail_smtp_username"
                                >    
                            </ValidationProvider>    
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email.smtp.password">{{ $t("label.password") }}</label>
                        <div class="col-lg-9 col-xl-6  align-items-center">            
                            <ValidationProvider>
                            <input 
                                name="email.smtp.password"
                                id="email.smtp.password"
                                type="text" 
                                class="form-control form-control-lg form-control-solid"
                                :placeholder="$t('placeholder.password')"
                                v-model="settings.mail_smtp_password"
                                >
                                <button class="col-xl-8 py-3 mt-5 btn btn-primary float-right" @click.prevent="sendTestEmail" ref="test_smtp">{{ $t("button.send_test_email") }}</button>
                            </ValidationProvider>    
                        </div>
                    </div>
            </div>
        </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  components: {     
  },
  metaInfo () { 
      return { title: this.$t("settings.meta.subtitle.emails")} 
  },
  data() {
      return {
          settings: {
            mail_from_address: null,
            mail_from_name: null,
            mail_smtp_host: null,
            mail_smtp_port: null,
            mail_smtp_username: null,
            mail_smtp_password: null,
          }
      }
  },
  beforeMount() {
    this.getSystemEmailSettings();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("settings.meta.title"), subtitle: this.$t("settings.meta.subtitle.emails") }]);
  },
  methods: {
    getSystemEmailSettings() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/settings/system-email")
        .then((response) => {
          this.settings = response.data;
          this.loading = false;
        });
    }, 
    sendTestEmail() {
      this.loading = true;
      const testButton = this.$refs["test_smtp"];
      testButton.classList.add("spinner", "spinner-light", "spinner-right");
      ApiService.setHeader();
      ApiService.apiGet("/smtp/send-test-email")
        .then((response) => {
          this.$toasted.info(response.data.message);
          this.loading = false;
          testButton.classList.remove("spinner","spinner-light","spinner-right");
        });
    }, 
    saveSystemEmailSettings(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 
        ApiService.apiPost("/settings/system-email", 
        { 
          'mail_from_address': this.settings.mail_from_address,
          'mail_from_name':  this.settings.mail_from_name,
          'mail_smtp_host': this.settings.mail_smtp_host,
          'mail_smtp_port':  this.settings.mail_smtp_port,
          'mail_smtp_username': this.settings.mail_smtp_username,
          'mail_smtp_password':  this.settings.mail_smtp_password,
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        }).catch(error => {
            checkErrors(error);
        });
    });
    }
  }
};
</script>
